'use client'

import Tags from '../tags'
import { Favourite } from '../favourite'
import DisablePrefetchLink from '@component/disablePrefetchLink'
import { concat, first, pick } from 'lodash-es'
import { houseDetail } from '@router'
import { getColliersClassName, getTime, joinStr } from '@utils'
import Avatar from '@component/avatar'
import Cover from '@component/cover'
import OfficeLine from '../office/line'
import HouseFacility from '../facility'
import { getComingSoonDateLabel, isVillageData } from '@utils/house'
import classNames from 'classnames'

export default function ListingsStandard({
  data,
  useFavorite = true,
  className,
  onClick,
  query,
  typeTag,
  showAgent = true,
  ...args
}) {

  const cover = first(concat(data.images, data.floorPlanImages))
  const agent = pick(first(data.agents) || {}, ['icon', 'name'])
  const street = joinStr(concat(data.street, data.suburbName || []), ', ')
  const isVillage = isVillageData(data)
  const comingSoonLabel = getComingSoonDateLabel(data, 'On Market {date}')

  return (
    <DisablePrefetchLink
      href={houseDetail(data, query)}
      className={classNames('flex flex-col justify-between rounded-sm overflow-hidden group shadow-carousel', className)}
      aria-label='go to property'
      onClick={() => onClick && onClick(data)}
      {...args}
    >
      <div className='relative'>
        <div className='absolute z-[1] left-0 top-0 p-10 w-full pointer-events-none'>
          <div className='flex items-start justify-between pointer-events-auto'>
            <Tags typeTag={typeTag} data={data}></Tags>
            {
              useFavorite && <Favourite data={data}></Favourite>
            }
          </div>
        </div>
        <Cover src={cover} alt={data.teaser || data.street} hoverScale className='aspect-[267/140]' width={267} height={140} defaultIcon='icon-house-cover'></Cover>
      </div>
      <div className='px-10 py-12 flex items-center'>
        <div className='flex-1 min-w-0'>
          <div className='text-sm text-black line-clamp-1'>
            {
              comingSoonLabel
                ? comingSoonLabel
                : (
                  isVillage
                    ? data.teaser
                    : [data.priceBold, data.priceLight].join(' ')
                )
            }
          </div>
          <address className='text-black text-sm not-italic line-clamp-1 mt-4'>
            <i className='icon icon-address text-font align-middle mr-6 sm:hidden'></i>
            {street}
          </address>
          <div className='mt-8 min-h-[21px]'>
            <HouseFacility data={data} itemClassName='!text-black'></HouseFacility>
          </div>
        </div>
        {
          showAgent && (
            <div className='ml-6'>
              {
                isVillage
                  ? <Avatar src={data.villageLogoDetail} className='' alt={agent.name} width={48} height={data.villageLogoDetail ? 64 : 48} circle={!data.villageLogoDetail} defaultIcon='icon-avatar'></Avatar>
                  : agent && <Avatar src={agent.icon} circle alt={agent.name} width={50} height={50} defaultIcon='icon-avatar' className={getColliersClassName(data.office)}></Avatar>
              }
            </div>
          )
        }
      </div>
      <OfficeLine data={data}></OfficeLine>
    </DisablePrefetchLink>
  )
}

export function SoldStandard({
  data,
  clickable = false
}) {

  const cover = first(concat(data.images, data.floorPlanImages))
  const street = joinStr(concat(data.street, data.suburbName || []), ', ')

  const isLink = data.slug && clickable
  const BoxTag = isLink ? DisablePrefetchLink : 'div'

  return (
    <BoxTag href={isLink && houseDetail(data)} className='block rounded-sm overflow-hidden group shadow-md' aria-label='go to property'>
      <div className='relative'>
        {
          data.soldInfo && (
            <div className='absolute z-[1] left-0 top-0 p-10 w-full h-full pointer-events-none flex flex-col justify-between items-start bg-image-mask-sm'>
              <div className='bg-red text-white text-xs rounded-sm px-6 py-2'>Sold on {getTime(data.soldInfo.soldDate, 'DD MMM YYYY')}</div>
              <div className='text-white text-sm'>{joinStr([data.priceBold, data.priceLight], ' ')}</div>
            </div>
          )
        }
        <Cover src={cover} alt={data.teaser} hoverScale className='aspect-[267/140]' width={267} height={140} defaultIcon='icon-house-cover'></Cover>
      </div>
      <div className='px-10 py-12 flex items-center'>
        <div className='flex-1 min-w-0'>
          <div className='text-font text-xs line-clamp-1 mt-4'>
            <i className='icon icon-address text-font align-middle mr-6'></i>{street}
          </div>
          <div className='mt-8 min-h-[21px]'>
            <HouseFacility data={data}></HouseFacility>
          </div>
        </div>
      </div>
    </BoxTag>
  )
}

export function SkeletonCard() {
  return (
    <div className='skeleton-wrap space-y-10'>
      <span style={{ aspectRatio: 267 / 140 }}></span>
      <div className='flex items-center'>
        <div className='flex-1 mr-10 space-y-10'>
          <span className='h-10'></span>
          <span className='h-10 w-3/4'></span>
        </div>
        <span className='w-50 h-50 !rounded-half'></span>
      </div>
    </div>
  )
}