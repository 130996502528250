import Image from 'next/image'
import { pick } from 'lodash-es'
import classNames from 'classnames'
import { isVillageData } from '@utils/house'

export default function OfficeLine({
  data,
  className,
  iconWidth = 200,
  iconHeight = 24,
  center = false,
}) {

  const isVillage = isVillageData(data)
  const office = pick(data.office || {}, ['icon', 'name', 'address', 'bgColor'])
  const icon = isVillage ? data.villageLogoBottom : office.icon
  const alt = isVillage ? data.teaser : (office.address || office.name)
  const bgColor = icon ? (isVillage ? data.villageColorBottom : office.bgColor) : null
  
  return (
    <div className={classNames('h-38 flex items-center px-10', { 'bg-neutral-1': !icon }, {'justify-center': center}, className)} style={{ backgroundColor: bgColor }}>
      {
        icon
          ? <Image src={icon} alt={alt || 'office cover'} className={classNames('object-contain', center ? 'object-center' : 'object-left')} width={iconWidth} height={iconHeight}></Image>
          : <i className='icon icon-offices text-neutral-5 text-base'></i>
      }
    </div>
  )
}